var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container_excel" },
    [
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small", icon: "el-icon-upload2" },
          on: { click: _vm.openImport }
        },
        [_vm._v(_vm._s(_vm.$t("IMPORT")))]
      ),
      _c(
        "el-button",
        {
          attrs: { type: "primary", size: "small", icon: "el-icon-download" },
          on: { click: _vm.openOut }
        },
        [_vm._v(_vm._s(_vm.$t("EXPORT")))]
      ),
      _c("importExcel", {
        attrs: {
          id: _vm.type,
          API: _vm.API,
          innerAPI: _vm.innerAPI,
          modelShow: _vm.showImport
        },
        on: { closeModel: _vm.closeModel }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }