var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "volumeSet" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.modelShow,
            width: "35%",
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "show-close": false,
            "append-to-body": true
          },
          on: {
            "update:visible": function($event) {
              _vm.modelShow = $event
            }
          }
        },
        [
          _c(
            "el-upload",
            {
              ref: "file",
              staticClass: "upload-demo",
              attrs: {
                action: "",
                "auto-upload": false,
                "on-change": _vm.addExcel,
                accept: ".xlsx,.xls",
                multiple: "",
                limit: 1
              }
            },
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("点击上传")
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip"
                },
                [_vm._v("只能上传xls/xlsx文件，且不超过10MB")]
              )
            ],
            1
          ),
          _c(
            "p",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "-30px" },
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.clickDownloadExcel }
                },
                [_vm._v(" 下载模版")]
              ),
              _c("el-button", { on: { click: _vm.closeModel } }, [
                _vm._v("取 消")
              ]),
              _c(
                "el-button",
                { attrs: { type: "success" }, on: { click: _vm.makeSure } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }